<template>
    <form class="mt-lg flex items-center justify-center"
        @submit.prevent="send">
        <label :for="blok.type"
            class="hidden">{{ blok.label }}</label>

        <div class="relative mx-auto md:w-1/2 lg:w-1/3">
            <input v-model="emailInput"
                :aria-label="blok.label"
                :type="blok.type"
                :placeholder="blok.placeholder"
                class="h-12 w-full rounded-xl px-md pr-xl text-black">

            <button type="submit"
                aria-label="Submit"
                class="absolute right-1 top-1 flex size-10 items-center justify-center rounded-lg
                    border border-solid border-red-500 bg-red-500 p-1">
                <v-icon icon="send"
                    class="text-2xl">
                </v-icon>
            </button>
        </div>
        <!--        <p class="text-white">{{ responseData.data.msg }}</p>-->
    </form>
</template>

<script setup lang="ts">
import { useToast } from '~~/store/toast';

defineProps<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    blok: any
}>();

const router = useRouter();
const emailInput = ref<string>('');
const { t } = useI18n();
const toast = useToast();
const { csrf } = useCsrf();

async function send() {
    await useFetch('/newsletter', {
        method: 'POST',
        body: {
            email: emailInput.value,
        },
        headers: {
            ...csrf ? {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                'csrf-token': csrf,
            } : {},
        },
    });

    toast.add('success', t('toast.contact-created'));

    emailInput.value = '';

    router.push(t('storyblok.newsletter-subscribed'));
}
</script>
